<template>
  <div></div>
</template>

<script>

export default {
  name: 'HomePage',
  components: {
  },
  setup() {
    let onMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return {
      onMobile
    }
  }
}
</script>
