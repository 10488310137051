<template>
    <div class="container-fluid container">
        <form class="container-fluid">
            <h1 class="fw-bold">Register</h1>
            <p class="text-muted register-text">Already have an Account?<button type="button" class="btn btn-link" @click="LoginRoute()">Login</button></p>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required v-model="email">
                <div id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</div>
            </div>
            <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" required v-model="password">
            </div>
            <div class="mb-3">
                <label for="exampleInputPassword2" class="form-label">Confirm Password</label>
                <input type="password" class="form-control" id="passwordRepeat" required v-model="passwordRepeat">
            </div>
            <button type="submit" class="btn btn-primary" @click.prevent="register()">Register</button>
        </form>
    </div>

</template>

<script>
import axios from 'axios';

export default{
    name: 'UserLogin',
    data() {
        return {
            email: '',
            password: '',
            passwordRepeat: ''
        }
    },
    methods:{
        LoginRoute(){
            this.$router.push('/login');
        },
        register() {
            let newUser = {
                email: this.email,
                password: this.password,
                passwordRepeat: this.passwordRepeat
            }
            axios.post('https://mydropuser.onrender.com/register/', newUser)
            .then(res => {
                if(res.status === 200) {
                    this.$router.push('/account');
                }
            })
            .catch(err => {
                if(err.response.status === 379) {
                    alert('Error');
                } else {
                    alert('Something went wrong');
                }
            })
        }
    },
}
</script>

<style scoped lang="scss">
.container{
    min-width: 30%;
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    text-align: left;
}

.mb-3{
    text-align: left;

    label{
        margin-bottom: .2rem;
    }

    #emailHelp{
        margin-top: .2rem;
    }
}

.register-text{
    margin-bottom: 2rem;
}
</style>