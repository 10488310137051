<template>
  <NavigationBar :onMobile="onMobile"></NavigationBar>
  <div class="router-view-container" :class="{mobile: onMobile}">
    <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
  </div>
  <FooterBar :onMobile="onMobile"></FooterBar>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import FooterBar from './components/FooterBar.vue';

export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterBar
  },
  setup() {
      let onMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      return {
        onMobile
      }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600&display=swap');
#app {
  font-family: 'Rubik', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

.router-view-container{
  width: 100%;
  min-height: calc(100vh - 171px - 58.8px);
  margin-top: 57.8px;
  display: flex;

  &.mobile{
    min-height: calc(100vh /* - 431px if substracting footer, view bugged*/ - 96.8px);
  }
}

.nav-margin{
  height: 57px;
  width: 100%;
}

.footer-margin{
  height: 170px;
  width: 100%;

  &.mobile{
    height: 430px;
  }
}

/* route trans */

.route-enter-from {
  opacity: 0;
}
.route-enter-active {
  transition: opacity .25s ease-out;
}
.route-leave-to {
  opacity: 0;
}
.route-leave-active {
  transition: opacity .25s ease-out;
}
</style>
