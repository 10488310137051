<template>
    <div class="container-fluid footer" v-bind:class="{'mobile': onMobile}">
        <img src="../assets/logo.png" alt="Logo" draggable="false">
        <div class="address text-dark">
            <span>MyDrop AG</span>
            <span>Straße 69</span>
            <span>187 Stadt</span>
        </div>
        <div class="contact">
            <a href="mailto:office@mydrop.at">office@mydrop.at</a>
            <a href="tel:+49123456789">+49 123 456789</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterBar',
    props: {
        onMobile: Boolean
    }
}
</script>

<style lang="scss" scoped>
.footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    font-weight: 300;
    line-height: 1.5rem;
    text-align: left;
    border-top: 1px solid black;

    &.mobile{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.footer img{
    width: auto;
    height: 90px;
    margin: 20px;
    user-select: none;
    image-rendering: optimizeQuality;
}
.address{
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-weight: 600;
    line-height: 1.5rem;
    height: 130px;
}

.contact{
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-weight: 300;
    line-height: 1.5rem;
    height: 130px;
}
</style>