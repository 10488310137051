<template>
  <div class="container-fluid container">
    In Progress
  </div>
</template>

<script>

export default {
  name: 'ProductsView',
  components: {
  },
  setup() {
    let onMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return {
      onMobile
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
}
</style>
