<template>
    <div class="container-fluid container text-start">
        <h1>Account</h1>
        <table class="table">
            <tbody>
                <tr>
                    <th scope="row">E-Mail</th>
                    <td>{{ user.email }}</td>
                    <td><button class="btn btn-link">Change</button></td>
                </tr>
                <tr>
                    <th scope="row">Password</th>
                    <td>*</td>
                    <td><button class="btn btn-link">Change</button></td>
                </tr>
                <tr>
                    <th scope="row">Options</th>
                    <th><button type="button" class="btn btn-danger" @click.prevent="deleteAcc()">Delete Account</button></th>
                </tr>
            </tbody>
        </table>
        
        <button type="button" class="btn btn-warning" @click.prevent="logout()">Logout</button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'AccountPage',
    data() {
        return {
            user: {}
        }
    },
    mounted() {
        axios.get('https://mydropuser.onrender.com/user/', {headers: {token: localStorage.getItem('token')}})
        .then(res => {
            this.user = res.data.user;
        })
    },
    created() {
        if(localStorage.getItem('token') === null) {
            this.$router.push('/login');
        }
    },
    methods: {
        deleteAcc() {
            axios.delete('https://mydropuser.onrender.com/user/', {headers: {token: localStorage.getItem('token')}})
            .then(res => {
                if(res.status === 200) {
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            })
            .catch(err => {
                if(err.response.status === 379) {
                    alert('Error');
                } else {
                    alert('Something went wrong');
                }
            })
        },
        logout() {
            localStorage.removeItem('token');
            this.$router.push('/login');
        }
    }
}
</script>

<style scoped lang="scss">
    .container{
        padding: 20px;
    }
</style>