<template>
<nav class="navbar navbar-expand-lg navbar-light fixed-top" style="z-index: 4; border-bottom: 1px solid black;">
  <div class="container-fluid" :class="{mobile: onMobile}">
    <a class="navbar-brand" href="#">
      <img src="../assets/logo.png" style="height: 30px !important;" alt="Logo">
      MyDrop
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <form class="d-flex search">
        <input class="form-control me-2 searchInput" type="search" placeholder="Search" aria-label="Search" :value="lastSearch">
        <button class="btn btn-outline-success" type="submit" @click.prevent="search()">Go</button>
    </form>
    <div class="collapse navbar-collapse justify-content-end links" id="navbarNav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="Home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="Products">Produkte</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="UserLogin">Login</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>
  export default {
    name: 'NavigationBar',
    methods: {
      Home() {
        this.$router.push('/')
      },
      Products() {
        this.$router.push('/products')
      },
      UserLogin() {
        this.$router.push('/login')
      },
      search() {
        this.$router.push({ name: 'Home', query: { search: document.querySelector('.searchInput').value }})
      }
    },
    props: {
      onMobile: Boolean,
      lastSearch: String
    }
  }
</script>

<style scoped lang="scss">
.navbar{
  background-color: #fff;
}
.container-fluid{
  justify-content: space-between;
  position: relative;

  &.mobile{
    justify-content: space-between;

    .links{
      width: calc(100vw - 2*20px);

      .nav-item{
        width: calc(100vw - 2*20px);
        text-align: center;
      }
    }
    >.search{
      margin: 0;
      width: 100% !important;
      margin-top: 20px;
    }
  }
}
.search{
    width: 50vw;
}

.links{
  width: max-content;
}

#navbarNav{
  max-width: 50px;
}
</style>
